window.onload = function() {
    window.dataLayer = window.dataLayer || [];
    const _g = function() {window.dataLayer.push(arguments);};
    const l = document.getElementsByTagName('a');
    Array.from(l).forEach(function(el) {
        let ename = el.getAttribute('data-ename');
        if (ename) {
            el.addEventListener('click', function(e) {
                _g('event', ename, {
                    'event_category': 'a-click'
                });
            })
        }
    });
}
